<template>
  <div>
    <CAlert show color="warning"><b>INFO : </b> to be able to view the following links, you must be logged as PA (not PAA). We recommend you to open another browser (or use incognito mode if applicable) and paste the links.</CAlert>
    <CRow>
      <CCol sm="6" md="4">
        <CCard accent-color="info">
          <CCardHeader>Sharepoint site</CCardHeader>
          <CCardBody>OneCloud sharepoint site <a href='https://engie.sharepoint.com/sites/AWSfoundations'>https://engie.sharepoint.com/sites/AWSfoundations</a></CCardBody>
        </CCard>
      </CCol>
      <CCol sm="6" md="4">
        <CCard accent-color="info">
          <CCardHeader>Confluence documentation</CCardHeader>
          <CCardBody>OneCloud documentation can be found here <a href='https://confluence.tools.digital.engie.com/display/AWSF'>https://confluence.tools.digital.engie.com/display/AWSF</a></CCardBody>
        </CCard>
      </CCol>
      <CCol sm="6" md="4">
        <CCard accent-color="info">
          <CCardHeader>RISE</CCardHeader>
          <CCardBody>Create support requests here <a href='https://ensemble-support.engie.com/'>https://ensemble-support.engie.com/</a></CCardBody>
        </CCard>
      </CCol>         
    </CRow>
    <CRow>
      <CCol sm="6" md="4">
        <CCard accent-color="info">
          <CCardHeader>Yammer channel</CCardHeader>
          <CCardBody>
              <CRow>
                  <CCol xs="6" md="3">
              <CIcon :height="42" :content="$options.brands.cibYammer" />
                  </CCol>
                  <CCol>            
              Follow lastest OneCloud news and announcements on yammer channel <a href='https://web.yammer.com/main/groups/eyJfdHlwZSI6Ikdyb3VwIiwiaWQiOiIxMjQ0NzAzMSJ9/all'>https://web.yammer.com/main/groups/eyJfdHlwZSI6Ikdyb3VwIiwiaWQiOiIxMjQ0NzAzMSJ9/all</a> 
                  </CCol>
              </CRow>
          </CCardBody>
        </CCard>
      </CCol>         
      <CCol sm="6" md="4">
        <CCard accent-color="info">
          <CCardHeader>Replay!</CCardHeader>
          <CCardBody>
              <CRow>
                  <CCol xs="6" md="3">
              <CIcon :height="42" :content="$options.freeSet.cilMediaPlay" />
                  </CCol><CCol>
              Replay OneCloud webinars on 
              <a href='https://web.microsoftstream.com/channel/db21795f-01c1-4afc-98c1-9b9257de4075'>microsoft stream </a>
                  </CCol>
              </CRow>
          </CCardBody>
        </CCard>
      </CCol> 
      <CCol sm="6" md="4">
        <CCard accent-color="info">
          <CCardHeader>Contact OneCloud Team</CCardHeader>
          <CCardBody>
              <CRow>
                  <CCol xs="6" md="3">
                    <CIcon :height="42" :content="$options.freeSet.cilEnvelopeClosed" />
                  </CCol>
                  <CCol>Still have questions ? Contact OneCloud team by mail <a href='mailto:aws-ccoe@engie.com'>aws-ccoe@engie.com</a></CCol>
              </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { freeSet } from "@coreui/icons";
import { brandSet as brands } from '@coreui/icons'

export default {
  name: "Dashboard",
  freeSet,
  brands,
  components: {},
  data() {
    return {};
  },
  methods: {}
};
</script>
